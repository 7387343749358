<template>
  <b-tabs
    content-class="mt-2"
    justified
    pills
  >
    <b-tab
      title="Modifica"
      active
    >
      <b-row
        v-if="!selectedCategory.id"
        class="my-lg-5 py-lg-5"
      >
        <b-col class="my-lg-5 py-lg-5 d-flex flex-column justify-content-center">
          <h2 class="text-center">
            Clicca su una
            <span
              class="cursor-pointer"
              @click="expandCategoryDefault"
            >
              <u> categoria</u>
            </span>
          </h2>
          <div
            class="mx-auto cursor-pointer"
            @click="expandCategoryDefault"
          >
            <b-avatar
              variant="primary"
              class=""
              size="80px"
            >
              <feather-icon
                icon="EditIcon"
                size="40"
              />
            </b-avatar>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-form
          class="w-100"
          @submit.prevent="validationFormSetCategoryFormObserver"
        >
          <validation-observer ref="setCategoryFormObserver">
            <b-col cols="12">
              <b-form-group
                :label="'Nome Categoria'"
                label-for="profile-name"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome Categoria"
                  rules="required"
                >
                  <b-form-input
                    v-model="selectedCategory.nome"
                    :placeholder="'es. Farmaci Generici'"
                    name="name-category"
                  />
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Codice'"
                label-for="codice"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Codice"
                  rules="required"
                >
                  <b-form-input
                    v-model="selectedCategory.codice"
                    :placeholder="'es. Farmaci Generici'"
                    name="codice"
                  />
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Categoria Padre'"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cat.Padre"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCategory.idCategoriaPadre"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="nome"
                    :clearable="false"
                    value="id"
                    :reduce="(val) => val.id"
                    filterable
                    :options="optionPadre"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      <div>Nessuna Categoria Trovata.</div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                optionPadre: {{ optionPadre }}
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Cataloghi'"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cataloghi"
                  rules=""
                >
                  <v-select
                    v-model="selectedCategory.cataloghi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descrizione"
                    :clearable="false"
                    multiple
                    value="id"
                    :reduce="(val) => ({ id: val.id })"
                    filterable
                    :options="cataloghiOptions"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      <div>Nessuna Catalogo Trovato.</div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                {{ cataloghiOptions }}
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="'Descrizione Categoria'"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="descrizione"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="selectedCategory.descrizione"
                    placeholder="descr. cat"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                optionPadre: {{ optionPadre }}
              </b-form-group>
              <b-button
                type="button"
                variant="outline-info"
                class="mr-2 btn btn-icon"
                @click="deleteNode"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
                type="button"
                variant="outline-warning"
                class="mr-2"
                @click="resetFormEdit"
              >
                Reset
              </b-button>
              <b-button
                type="submit"
                variant="success"
              >
                Modifica
              </b-button>
            </b-col>
          </validation-observer>
        </b-form>
      </b-row>
    </b-tab>
    <b-tab
      title="Aggiungi"
      lazy
    >
      <b-row>
        <b-form
          class="w-100"
          @submit.prevent="validationFormSetNewCategoryFormObserver"
        >
          <validation-observer ref="setNewCategoryFormObserver">
            <b-col cols="12">
              <b-form-group
                :label="'Nome Categoria'"
                label-for="profile-name"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome Categoria"
                  rules="required"
                >
                  <b-form-input
                    v-model="newCategory.nome"
                    :placeholder="'es. Farmaci Generici'"
                    name="name-category"
                  />
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Codice'"
                label-for="codice"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Codice"
                  rules="required"
                >
                  <b-form-input
                    v-model="newCategory.codice"
                    :placeholder="'es. Farmaci Generici'"
                    name="codice"
                  />
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Categoria Padre'"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cat.Padre"
                  rules="required"
                >
                  <v-select
                    v-model="newCategory.idCategoriaPadre"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="nome"
                    :clearable="false"
                    value="id"
                    :reduce="(val) => val.id"
                    filterable
                    :options="optionPadre"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      <div>Nessuna Categoria Trovata.</div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                optionPadre: {{ optionPadre }}
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Cataloghi'"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cataloghi"
                  rules=""
                >
                  <v-select
                    v-model="newCategory.cataloghi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descrizione"
                    :clearable="false"
                    multiple
                    value="id"
                    :reduce="(val) => ({ id: val.id })"
                    filterable
                    :options="cataloghiOptions"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      <div>Nessuna Catalogo Trovato.</div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                {{ cataloghiOptions }}
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Descrizione Categoria'"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="descrizione"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="newCategory.descrizione"
                    placeholder="descr. cat"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                optionPadre: {{ optionPadre }}
              </b-form-group>
            </b-col>
            newCategory {{ newCategory }}
            <b-col class="d-flex justify-content-end">
              <b-button
                type="submit"
                variant="success"
              >
                Aggiungi
              </b-button>
            </b-col>
          </validation-observer>
        </b-form>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BCol, BRow, BButton, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormTextarea, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import axios from 'axios'
import { deleteCategorie } from '@/@core/api-service/catalog/catalogs'
import FeatherIcon from '../../../../@core/components/feather-icon/FeatherIcon.vue'
// import { getCombo } from '@/@core/api-service/shared/combo'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BAvatar,
    FeatherIcon,
  },
  props: {
    selectedCategory: {
      type: Object,
      required: false,
      default: () => ({
        id: 0,
        nome: '',
        cataloghi: [],
        descrizione: '',
        codice: '',
        idCategoriaPadre: '',
      }),
    },
    /* cataloghiOptionsProps: {
      type: Array,
      required: false,
      default: () => [],
    }, */
  },
  data() {
    return {
      required,
      categories: [],
      selectedCategoryData: {
        id: 0,
        nome: '',
        cataloghi: [],
        descrizione: '',
        codice: '',
        idCategoriaPadre: '',
        ordinamento: 0,
        escludiCataloghi: 'N',
      },
      newCategory: {
        id: 0,
        nome: '',
        cataloghi: [],
        descrizione: '',
        codice: '',
        idCategoriaPadre: '',
        ordinamento: 0,
        escludiCataloghi: 'N',
      },
    }
  },
  computed: {
    optionPadre() {
      return this.$store.getters['categories/getCategories'].map(el => ({ id: el.id, nome: el.nome }))
    },
    cataloghiOptions() {
      return this.$store.getters['catalogs/getCatalogs']
    },
  },
  methods: {
    /* getCataloghiOptions() {
      getCombo().then(res => {
        if (res.data.esito === 'OK') {
          console.log('res. combo', res)
          this.cataloghiOptions = [...res.data.cataloghi]
        }
      })
    }, */
    async loadCategories() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, [{}],
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Categoria',
            metodo: 'getCategorie',
            ACL: '',
          },
        }).then(res => {
        this.categories = [...res.data.categorie.sort((a, b) => parseFloat(a.ordinamento) - parseFloat(b.ordinamento))].map(el => ({ id: el.id, nome: el.nome }))
      }).catch(e => e)
    },
    expandCategoryDefault() {
      // console.log('exapndCategory')
      this.$emit('expandcategorydefault')
    },
    resetFormEdit() {
      this.$emit('resetformedit')
    },
    validationFormSetNewCategoryFormObserver() {
      let categorytoUpdate = []
      this.$refs.setNewCategoryFormObserver.validate().then(async success => {
        if (success) {
          await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([this.newCategory]),
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Cat-Categoria',
                metodo: 'setCategoria',
                ACL: '',
              },
            })
            .then(res => {
              if (res.data.esito === 'OK') {
                categorytoUpdate = [...res.data.categorie]
                // console.log(categorytoUpdate)
                this.allowEditing = true
                // console.log(this.allowEditing)
                // this.resetFormEdit()
                this.$emit('updatetreeview', {
                  id: categorytoUpdate[0].id, nome: categorytoUpdate[0].nome, descrizione: categorytoUpdate[0].descrizione, idCategoriaPadre: categorytoUpdate[0].id_categoria_padre,
                })
                // this.selectedCatagory.idCategoriaPadre = Number(category[0].id_categoria_padre)
                // this.$emit('updatetreeview')
                /* this.$refs.treeview.updateNode(String(category[0].id), String(category[0].nome))
                this.$refs.treeview.refreshNode(String(category[0].id))
                this.$refs.treeview.moveNodes(String(category[0].id), String(category[0].id_categoria_padre), 0, false) */
              }
            }).catch(e => e)
        }
      })
    },
    validationFormSetCategoryFormObserver() {
      let categorytoUpdate = []
      this.$refs.setCategoryFormObserver.validate().then(async success => {
        if (success) {
          await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([this.selectedCategory]),
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Cat-Categoria',
                metodo: 'setCategoria',
                ACL: '',
              },
            })
            .then(res => {
              if (res.data.esito === 'OK') {
                categorytoUpdate = [...res.data.categorie]
                // console.log(categorytoUpdate)
                this.allowEditing = true
                // console.log(this.allowEditing)
                this.$emit('updatetreeviewexists', {
                  id: categorytoUpdate[0].id, nome: categorytoUpdate[0].nome, descrizione: categorytoUpdate[0].descrizione, idCategoriaPadre: categorytoUpdate[0].id_categoria_padre,
                })
                // this.selectedCatagory.idCategoriaPadre = Number(category[0].id_categoria_padre)
                // this.$emit('updatetreeview')
                /* this.$refs.treeview.updateNode(String(category[0].id), String(category[0].nome))
                this.$refs.treeview.refreshNode(String(category[0].id))
                this.$refs.treeview.moveNodes(String(category[0].id), String(category[0].id_categoria_padre), 0, false) */
              }
            }).catch(e => e)
        }
      })
    },
    deleteNode() {
      deleteCategorie([{ id: this.selectedCategory.id }]).then(res => {
        if (res.data.esito === 'OK') {
          // console.log(res.data)
          this.$emit('removenode', res.data.categorie[0].id)
        }
      }).catch(e => e)
    },
  },
}
</script>
