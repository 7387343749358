<script>
/* eslint-disable */
</script>
<template>
  <b-row class="">
    <b-col md="4">
        <div
            style="max-width: 400px"
            class="mx-auto"
        >
          <ejs-treeview
            id="treeview"
            ref="treeview"
            :fields="fields"
            :allow-drag-and-drop="true"
            :allow-multi-selection="false"
            :allow-editing="true"
            :node-dropped="dataSourceChanged"
            :node-drag-stop="dataSourceChanged2"
            :nodeDragStop="dragStop" 
            :node-selected="getNoteFromServer"
            :node-edited="nodeEdited"
            :node-clicked="nodeClicked"
            /><!-- :data-source-changed="dataSourceChanged2" -->
        </div>
    </b-col>
    <b-col md="8">
      <b-card>
        <tabs-edit-add-event-handler
          :selected-category="selectedCatagory"
          @resetformedit="resetFormEdit"
          @expandcategorydefault="expandCategoryDefault"
          @updatetreeview="updateTreeview"
          @updatetreeviewexists="updateTreeviewExists"
          @removenode="removeNode"
        />
          <h1>{{ selectedCatagory }} </h1>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import {
  BRow, BCol, BCard, BButton, VBToggle, BForm, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { getCombo } from '@/@core/api-service/shared/combo'
// import { findNode, swap } from '@core/utils/nestedObject'
// import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import TabsEditAddEventHandler from './category/TabsEditAddEventHandler.vue'

Vue.use(TreeViewPlugin)

const data1 = [
  {
    id: '01',
    name: 'Local Disk (C:)',
    expanded: true,
    subChild: [
      {
        id: '01-01',
        name: 'Program Files',
        subChild: [
          { id: '01-01-01', name: 'Windows NT' },
          { id: '01-01-02', name: 'Windows Mail' },
          { id: '01-01-03', name: 'Windows Photo Viewer' },
        ],
      },
      {
        id: '01-02',
        name: 'Users',
        expanded: true,
        subChild: [
          { id: '01-02-01', name: 'Smith' },
          { id: '01-02-02', name: 'Public' },
          { id: '01-02-03', name: 'Admin' },
        ],
      },
      {
        id: '01-03',
        name: 'Windows',
        subChild: [
          { id: '01-03-01', name: 'Boot' },
          { id: '01-03-02', name: 'FileManager' },
          { id: '01-03-03', name: 'System32' },
        ],
      },
    ],
  },
  {
    id: '02',
    name: 'Local Disk (D:)',
    subChild: [
      {
        id: '02-01',
        name: 'Personals',
        subChild: [
          { id: '02-01-01', name: 'My photo.png' },
          { id: '02-01-02', name: 'Rental document.docx' },
          { id: '02-01-03', name: 'Payslip.pdf' },
        ],
      },
      {
        id: '02-02',
        name: 'Projects',
        subChild: [
          { id: '02-02-01', name: 'Blazor Application' },
          { id: '02-02-02', name: 'TypeScript Application' },
          { id: '02-02-03', name: 'React Application' },
        ],
      },
      {
        id: '02-03',
        name: 'Office',
        subChild: [
          { id: '02-03-01', name: 'Work details.docx' },
          { id: '02-03-02', name: 'Weekly report.docx' },
          { id: '02-03-03', name: 'Wishlist.csv' },
        ],
      },
    ],
  },
  {
    id: '03',
    name: 'Local Disk (E:)',
    icon: 'folder',
    subChild: [
      {
        id: '03-01',
        name: 'Pictures',
        subChild: [
          { id: '03-01-01', name: 'Wind.jpg' },
          { id: '03-01-02', name: 'Stone.jpg' },
          { id: '03-01-03', name: 'Home.jpg' },
        ],
      },
      {
        id: '03-02',
        name: 'Documents',
        subChild: [
          { id: '03-02-01', name: 'Environment Pollution.docx' },
          { id: '03-02-02', name: 'Global Warming.ppt' },
          { id: '03-02-03', name: 'Social Network.pdf' },
        ],
      },
      {
        id: '03-03',
        name: 'Study Materials',
        subChild: [
          { id: '03-03-01', name: 'UI-Guide.pdf' },
          { id: '03-03-02', name: 'Tutorials.zip' },
          { id: '03-03-03', name: 'TypeScript.7z' },
        ],
      },
    ],
  },
]

export default Vue.extend({
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    // AppCollapseItem,
    // AppCollapse,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
    TabsEditAddEventHandler,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      title: 'Modifica Categoria',
      required,
      allowEditing: true,
      categories: [],
      cataloghiOptions: [],
      selectedCatagory: {
        id: 0,
        ordinamento: 0,
        escludiCataloghi: 'N',
          // padre: { id: '', nome: '' },
      },
      fields: {
        dataSource: [{
            id: 1,
            nome: 'DEFAULT',
            children: [],
        }], id: 'id', text: 'nome', child: 'children',
        // dataSource: this.categories, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild',
      },
      // img default
      // eslint-disable-next-line global-require
      img: 'https://fakeimg.pl/50/',
      selectedCategory: {
        label: 'Level three 1',
        id: 2,
        description: 'sono descrizione1',
      },
      data: [{
        label: 'Tutte le categorie',
        id: 1,
        children: [
          {
            label: 'Level three 1',
            id: 2,
            description: 'sono descrizione1',
            children: [{ label: 'level three 1.1', id: 6, description: 'sono descrizione1.1' }],
          },
          { label: 'Level three 2', id: 3, description: 'sono descrizione2' },
          { label: 'Level three 3', id: 4, description: 'sono descrizione3' },
          { label: 'Level three 4', id: 5, description: 'sono descrizione4' },
        ],
      }],
      optionCat: ['Generali', 'Mangimi', 'Consumabili'],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      content_visible: !this.collapsed,
      collapsed: false,

    }
  },
  computed: {
      optionPadre() {
          return this.$store.getters['categories/getCategories'].map(el => ({ id: el.id, nome: el.nome }))
      },
      allowEditingComputed() {
          return this.allowEditing
      }
  },
  mounted() {
      this.loadCategories()
      getCombo().then(res => {
        if (res.data.esito === 'OK') {
          console.log('res. combo', res)
          // this.cataloghiOptions = [...res.data.cataloghi]
          this.$store.dispatch('catalogs/setCatalogs', res.data.cataloghi)
        }
      })
  },
  methods: {
    removeNode(id) {
      this.$refs.treeview.removeNodes([String(id)])
    },
    updateTreeviewExists(obj) {
      console.log('updateTreeviewExists', obj)
      this.$refs.treeview.moveNodes([String(obj.id)], String(obj.idCategoriaPadre), 0, false)
      // console.log(this.$refs.treeview.getTreeData())
      this.$refs.treeview.refreshNode(obj.idCategoriaPadre)
      this.$refs.treeview.refreshNode([String(obj.id)])
      this.$refs.treeview.collapseAll()
    },
    updateTreeview(obj) {
      console.log('obj emit', obj)
      this.$refs.treeview.addNodes([{ id: String(obj.id), nome: obj.nome , children: []}],  String(obj.idCategoriaPadre))
      this.$refs.treeview.moveNodes(String(obj.id), String(obj.idCategoriaPadre), 0, false)
      // console.log(this.$refs.treeview.getTreeData())
      this.$refs.treeview.refreshNode(obj.idCategoriaPadre)
      this.$refs.treeview.refreshNode([String(obj.id)])
      /* this.$refs.treeview.updateNode(String(obj.id), String(obj.nome))
      this.$refs.treeview.refreshNode(String(obj.id))
      this.$refs.treeview.moveNodes(String(obj.id), String(obj.idCategoriaPadre), 0, false) */
      // this.$refs.treeview.collapseAll()
    },
    expandCategoryDefault() {
      this.$refs.treeview.expandAll('1', 2)
    },
    resetFormEdit() {
      // console.log('ciao')
      this.selectedCatagory = {
        id: 0,
        nome: '',
        cataloghi: [],
        descrizione: '',
        codice: '',
        idCategoriaPadre: '',
        ordinamento: 0,
        escludiCataloghi: 'N',
      }
      this.$refs.treeview.collapseAll()
    },
    nodeClicked() {
      
    },
    dataSourceChanged(args) {
      // console.log('dataSourceChanged', args)
      const tree = this.$refs.treeview.getTreeData()
      // console.log('tree', tree)

      let categoryStart = this.$refs.treeview.getTreeData(String(args.draggedNodeData.id))
      let categoryEnd = this.$refs.treeview.getTreeData(String(args.droppedNodeData.id))
      const { idCategoriaPadre } = categoryEnd
      if(idCategoriaPadre === null || [0, 1, 2].some(el => el === args.dropLevel))  {
        // console.log('nullsss')
        categoryEnd = this.$refs.treeview.getTreeData(String(1))
      }
      if(!args.droppedNodeData.hasChildren && ['Before', 'After'].some(el => el === args.position)) {
        // console.log('nulll!!!!')
        categoryEnd = this.$refs.treeview.getTreeData(String(args.droppedNodeData.parentID))
      }
      // mappo la categoria finale/destinazione
      // categoryEnd = categoryEnd.map(el => ({ ...el, cataloghi: [{ id: 1 }], escludiCataloghi: 'Y', children: el.children.map((child, index) => ({ ...child, ordinamento: index }))}))
      // console.log('categoryEnd', categoryEnd)
      categoryEnd = this.prepareArrayFatherForServer(categoryEnd, 'Y') 
      const { children, ...categoryEndFatrher } = categoryEnd[0]
      // children del padre/ destinazioen mappati con il nuovo ordinamento, id padre , eslcudiCataloghI(solo per treeview), e cataloghi deafault
      const childrenFinale = this.prepareArrayChildrenForServer(children, 'Y', categoryEndFatrher.id)
      /* children.forEach(object => {
        object.escludiCataloghi = 'Y'
        object.cataloghi = [{ id: 1 }]
        object.idCategoriaPadre = categoryEndFatrher.id
        delete object['children']
      }) */
      // console.log('child reeeen', childrenFinale)
      // categoria padre mappata senza children
      // console.log('categoryEndFatrher', categoryEndFatrher)

      this.setCategories([...childrenFinale, categoryEndFatrher])
      // console.log('categoryStart', categoryStart)
    },
    prepareArrayChildrenForServer(children, escludiCataloghi, idCategoriaPadre) {
      children.forEach(object => {
        object.escludiCataloghi = escludiCataloghi,
        object.cataloghi = [{ id: 1 }]
        object.idCategoriaPadre = idCategoriaPadre
        delete object['children']
      })
      return children
    },
    prepareArrayFatherForServer(father, escludiCataloghi) {
      return father.map(el => ({ ...el, cataloghi: [{ id: 1 }], escludiCataloghi: escludiCataloghi, children: el.children.map((child, index) => ({ ...child, ordinamento: index }))}))
    },
    async setCategories(categories) {
      console.log('categories set', categories)
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(categories),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Categoria',
            metodo: 'setCategoria',
            ACL: '',
          },
        }).then(res => {
            console.log(res)
            if (res.data.esito === 'OK') {
                const category = [...res.data.categorie]
                // console.log(category)
                this.allowEditing = true
                // console.log(this.allowEditing)
                this.selectedCatagory.idCategoriaPadre = category[0].idCategoriaPadre
                this.selectedCatagory.nome = category[0].nome
                this.selectedCatagory.descrizione = category[0].descrizione
            }
        }).catch(e => e)
      // console.log('this.categories', this.categories)
    },
    dataSourceChanged2() {
      // console.log('drop stop', args)
    },
    onChangeTreeview() {
      // console.log(this.fields)
    },
    onSubmit() {
      // console.log(form)
    },
    changeImg() {},
    // all tree event
    showSelectedCategory(category) {
      // diverso id da 'TUTTE LE CATEGORIE'
      if (category.id !== 1) {
        // console.log(category.id)
        this.selectedCategory = { ...category }
        // console.log(this.selectedCategory)
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      // console.log('dropNode', dropNode)
      // console.log('draggingNode', draggingNode)
      // console.log('type', type)
      if (draggingNode && type && dropNode.data.id === 1) {
        return false
      }
      return true
    },
    allowDrag(draggingNode) {
      // console.log(this.data)
      return draggingNode.data.label.indexOf('Tutte le Categorie') === -1
    },
    getPadre(id) {
        this.$store.dispatch('categories/setPadreByID', id)
        // console.log(this.$store.getters['categories/getPadreObj'])
        return this.$store.getters['categories/getPadreObj']
    },
    async loadCategories() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, [{}],
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Categoria',
            metodo: 'getCategorie',
            ACL: '',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.categories = [...res.data.categorie.sort((a, b) => parseFloat(a.ordinamento) - parseFloat(b.ordinamento))]
          this.$store.dispatch('categories/setCategories', this.categories)

          // this.$store.dispatch('categories/setCategories', this.categories)
          // this.apiLoaded = false
        }
      })
        .catch(e => e)
      this.categories = this.categories.map(el => ({ children: [], ...el }))
      // console.log(this.categories)
      this.categories = this.fromListToTreeView(this.categories)
      // console.log('this.$refs.treeview', this.$refs.treeview.fields.dataSource[0].children)
      // this.$refs.treeview.fields.dataSource[0].children.push(this.categories)
      // this.$refs.treeview.addNode({ ...this.categories }, this.$refs.treeview.selectedNode[0])
      // console.log('this.$refs.treeview', this.$refs.treeview)
      this.$refs.treeview.removeNodes("1")
      this.$refs.treeview.addNodes(this.categories, null)
      // this.$refs.treeview.addNodes({ id: 150, nome: 'ciao', children: [] })
    },
    fromListToTreeView(list1) {
      const list = list1
      // console.log('list', list)
      const map = {}
      let node = []
      const roots = []
      let i = 0
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i
        list[i].children = []
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.idCategoriaPadre !== null) {
          // check if has parent -> ordina i figli in maniera crescente su ordinamento e pusha il nodo
          node.children = node.children.sort((a, b) => parseFloat(a.ordinamento) - parseFloat(b.ordinamento))
          // console.log(node)
          list[map[node.idCategoriaPadre]].children.push(node)
          // figli ordinati in maniera crescente su ordinamento
          list[map[node.idCategoriaPadre]].children = list[map[node.idCategoriaPadre]].children.sort((a, b) => parseFloat(a.ordinamento) - parseFloat(b.ordinamento))
        } else {
          node.children = node.children.sort((a, b) => parseFloat(b.ordinamento) - parseFloat(a.ordinamento))
          // console.log('nodeee', node)
          roots.push(node)
        }
      }
      return roots
    },
    dragStop(args) {
        // console.log('aaarrrrgsssssposition', args.position)
        if (args.dropLevel === 1 && (args.position === 'Before' || args.position === 'After') ) {
          // console.log('stttoooop', args)
            args.cancel = true;
        }
    },
    getNoteFromServer(args) {
        // console.log('argsssss', args)
        if (args.nodeData.parentID !== null) {
            this.getCategory(args.nodeData.id)
        }
    },
    async getCategory(id) {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([{ id: id}]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Categoria',
            metodo: 'getCategoria',
            ACL: '',
          },
        }).then(res => {
            // console.log(res)
        if (res.data.esito === 'OK') {
            this.selectedCatagory = { ...res.data.categoria }
            this.selectedCatagory.cataloghi = this.selectedCatagory.cataloghi.map(el => ({ id: el.id_catalogo}))
            this.selectedCatagory.escludiCataloghi = 'N'
            this.$store.dispatch('categories/setPadreByID', this.selectedCatagory.idCategoriaPadre)
            // const { nome, id } = this.$store.getters['categories/getPadreObj']
            /* this.selectedCatagory.padre = {
                id, nome
            } */
        }
      })
        .catch(e => e)

    },
    validationForm() {
      // this.selectedCatagory.idCategoriaPadre = 1
      if(!this.selectedCatagory.hasOwnProperty('id')) this.selectedCatagory.id = 0
      this.selectedCatagory.ordinamento = 0
      this.selectedCatagory.escludiCataloghi = 'N'
      // console.log(this.selectedCatagory)
      this.$refs.setCategoryFormObserver.validate().then(async success => {
        if (success) {
          await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([this.selectedCatagory]),
            {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
              classe: 'Cat-Categoria',
              metodo: 'setCategoria',
              ACL: '',
            },
          }).then(res => {
              // console.log(res)
              if (res.data.esito === 'OK') {
                const category = [...res.data.categorie]
                // console.log(category)
                this.allowEditing = true
                // console.log(this.allowEditing)
                this.selectedCatagory.idCategoriaPadre = Number(category[0].id_categoria_padre)
                this.$refs.treeview.updateNode(String(category[0].id), String(category[0].nome))
                this.$refs.treeview.refreshNode(String(category[0].id))
                this.$refs.treeview.moveNodes(String(category[0].id), String(category[0].id_categoria_padre), 0, false)
              }
          }).catch(e => e)
        }
      })
    },
    nodeEdited(args) {
      console.log(args)
     //  const ciao = this.$refs.treeview.getNode(String(args.nodeData.id))
      console.log(this.selectedCatagory)
      this.setCategories([{ ...this.selectedCatagory }])
      /* const nodeToEdit = {
        escludiCataloghi: 'Y',
        id: args.nodeData.id,
        idCategoriaPadre: args.nodeData.parentID,
        nome: args.newText,
        cataloghi: [],
        // descrizione: a
      } */
      // console.log(nodeToEdit)
    }
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// scss resettato su _collapsw.scss dropdown box-shadow commentato
.myCard{
  background-color: transparent;
  box-shadow: initial;
  padding-left: 0;
  padding-top:0;
  padding-bottom:0;
}
.myCard > .card-body {
  margin-bottom: 0;
}

.el-tree {
  background: transparent;
}

.leavePadding .card-body {
  padding-left: 11px;
  padding-right: 11px;
}
 .control_wrapper {
        display: block;
        max-width: 350px;
        max-height: 350px;
        margin: auto;
        overflow: auto;
        border: 1px solid #dddddd;
        border-radius: 3px;
    }
</style>
