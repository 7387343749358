var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',{attrs:{"content-class":"mt-2","justified":"","pills":""}},[_c('b-tab',{attrs:{"title":"Modifica","active":""}},[(!_vm.selectedCategory.id)?_c('b-row',{staticClass:"my-lg-5 py-lg-5"},[_c('b-col',{staticClass:"my-lg-5 py-lg-5 d-flex flex-column justify-content-center"},[_c('h2',{staticClass:"text-center"},[_vm._v(" Clicca su una "),_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.expandCategoryDefault}},[_c('u',[_vm._v(" categoria")])])]),_c('div',{staticClass:"mx-auto cursor-pointer",on:{"click":_vm.expandCategoryDefault}},[_c('b-avatar',{attrs:{"variant":"primary","size":"80px"}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"40"}})],1)],1)])],1):_c('b-row',[_c('b-form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.validationFormSetCategoryFormObserver($event)}}},[_c('validation-observer',{ref:"setCategoryFormObserver"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Nome Categoria',"label-for":"profile-name","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Nome Categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":'es. Farmaci Generici',"name":"name-category"},model:{value:(_vm.selectedCategory.nome),callback:function ($$v) {_vm.$set(_vm.selectedCategory, "nome", $$v)},expression:"selectedCategory.nome"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Codice',"label-for":"codice","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Codice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":'es. Farmaci Generici',"name":"codice"},model:{value:(_vm.selectedCategory.codice),callback:function ($$v) {_vm.$set(_vm.selectedCategory, "codice", $$v)},expression:"selectedCategory.codice"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Categoria Padre',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Cat.Padre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","reduce":function (val) { return val.id; },"filterable":"","options":_vm.optionPadre},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Nessuna Categoria Trovata.")])]}}],null,true),model:{value:(_vm.selectedCategory.idCategoriaPadre),callback:function ($$v) {_vm.$set(_vm.selectedCategory, "idCategoriaPadre", $$v)},expression:"selectedCategory.idCategoriaPadre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" optionPadre: "+_vm._s(_vm.optionPadre)+" ")],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Cataloghi',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Cataloghi","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"descrizione","clearable":false,"multiple":"","value":"id","reduce":function (val) { return ({ id: val.id }); },"filterable":"","options":_vm.cataloghiOptions},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Nessuna Catalogo Trovato.")])]}}],null,true),model:{value:(_vm.selectedCategory.cataloghi),callback:function ($$v) {_vm.$set(_vm.selectedCategory, "cataloghi", $$v)},expression:"selectedCategory.cataloghi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "+_vm._s(_vm.cataloghiOptions)+" ")],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Descrizione Categoria',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"descr. cat","rows":"2"},model:{value:(_vm.selectedCategory.descrizione),callback:function ($$v) {_vm.$set(_vm.selectedCategory, "descrizione", $$v)},expression:"selectedCategory.descrizione"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" optionPadre: "+_vm._s(_vm.optionPadre)+" ")],1),_c('b-button',{staticClass:"mr-2 btn btn-icon",attrs:{"type":"button","variant":"outline-info"},on:{"click":_vm.deleteNode}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"button","variant":"outline-warning"},on:{"click":_vm.resetFormEdit}},[_vm._v(" Reset ")]),_c('b-button',{attrs:{"type":"submit","variant":"success"}},[_vm._v(" Modifica ")])],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Aggiungi","lazy":""}},[_c('b-row',[_c('b-form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.validationFormSetNewCategoryFormObserver($event)}}},[_c('validation-observer',{ref:"setNewCategoryFormObserver"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Nome Categoria',"label-for":"profile-name","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Nome Categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":'es. Farmaci Generici',"name":"name-category"},model:{value:(_vm.newCategory.nome),callback:function ($$v) {_vm.$set(_vm.newCategory, "nome", $$v)},expression:"newCategory.nome"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Codice',"label-for":"codice","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Codice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":'es. Farmaci Generici',"name":"codice"},model:{value:(_vm.newCategory.codice),callback:function ($$v) {_vm.$set(_vm.newCategory, "codice", $$v)},expression:"newCategory.codice"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Categoria Padre',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Cat.Padre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","reduce":function (val) { return val.id; },"filterable":"","options":_vm.optionPadre},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Nessuna Categoria Trovata.")])]}}],null,true),model:{value:(_vm.newCategory.idCategoriaPadre),callback:function ($$v) {_vm.$set(_vm.newCategory, "idCategoriaPadre", $$v)},expression:"newCategory.idCategoriaPadre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" optionPadre: "+_vm._s(_vm.optionPadre)+" ")],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Cataloghi',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Cataloghi","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"descrizione","clearable":false,"multiple":"","value":"id","reduce":function (val) { return ({ id: val.id }); },"filterable":"","options":_vm.cataloghiOptions},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Nessuna Catalogo Trovato.")])]}}],null,true),model:{value:(_vm.newCategory.cataloghi),callback:function ($$v) {_vm.$set(_vm.newCategory, "cataloghi", $$v)},expression:"newCategory.cataloghi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "+_vm._s(_vm.cataloghiOptions)+" ")],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Descrizione Categoria',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"descr. cat","rows":"2"},model:{value:(_vm.newCategory.descrizione),callback:function ($$v) {_vm.$set(_vm.newCategory, "descrizione", $$v)},expression:"newCategory.descrizione"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" optionPadre: "+_vm._s(_vm.optionPadre)+" ")],1)],1),_vm._v(" newCategory "+_vm._s(_vm.newCategory)+" "),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"type":"submit","variant":"success"}},[_vm._v(" Aggiungi ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }